<template>
  <article :class="['product-teaser', { 'has-link': href }]" @click="delegateClick" :ref="`ProductTeaserContainer_${productId}`">
    <template v-if="!isVisible">
      <a v-if="href" :href="href" ref="link" class="stretched-link" :aria-describedby="`ProductTeaser_${_uid}`" />
      <div class="product-teaser-contentplaceholder">
        <div :style="contentPlaceholderStyles">&nbsp;</div>
      </div>
    </template>
    <template v-else>
      <a v-if="href" :href="href" ref="link" class="stretched-link" :aria-describedby="`ProductTeaser_${_uid}`" />

      <b-card :id="`ProductTeaser_${_uid}`" no-body>
        <aspect-ratio ratio="4x3" class="card-img">
          <img v-if="productImgLoaded" :src="productImage" :alt="imgAlt" :width="imgWidth" :height="imgHeight" />

          <div v-else class="is-fallback">
            <lazy-icon icon="images" />
          </div>
        </aspect-ratio>

        <div class="eyecatcher-stack">
          <slot name="eyecatcher" />
        </div>

        <b-card-body>
          <c-product-delivery-state :stateId="stateId" :stockAmount="stockAmount" :restock-time="restockTime" popover :translationsPath="`${tPath}.states`" />

          <div class="product-description">
            <slot />

            <c-product-price :productId="productId" :priceinfo="priceinfo" :configuration="defaultConfiguration" align-h="start" hideVatInfo />
          </div>
        </b-card-body>

        <b-card-footer>
          <div class="form-row" :class="{ 'align-items-center': showColors, 'justify-content-end': !showColors }">
            <div class="col" v-if="showColors">
              <c-product-colors @color-mouseover="colorMouseOver" @color-mouseleave="colorMouseLeave" v-if="colors" :colors="colors" maxItems="6" compact />
            </div>
            <div class="col col-auto">
              <c-product-wishlist-button :productId="productId" link @click.native.stop="" />
            </div>
            <div class="col col-auto" v-if="$slots.shoppingcart">
              <slot name="shoppingcart" />
            </div>
          </div>
        </b-card-footer>
      </b-card>
    </template>
  </article>
</template>

<script>
import { getPath } from '@/assets/js/helper/dom';
import { PRODUCT_ASSEMBLYTYPES } from '@/constants';

import debounce from 'lodash/debounce';

import AspectRatio from '@/components/private/AspectRatio';

const FAMILY_GIFTCARD = 49

export default {
  name: 'ProductTeaser',
  components: {
    AspectRatio
  },
  props: {
    href: {
      type: String,
      default: null
    },
    imgSrc: {
      type: String,
      default: null
    },
    imgAlt: {
      type: String,
      default: null
    },
    imgWidth: {
      type: String,
      default: null
    },
    imgHeight: {
      type: String,
      default: null
    },
    productId: {
      type: String,
      required: true
    },
    stateId: {
      type: String,
      required: true
    },
    stockAmount: {
      type: Number,
      default: 0
    },
    restockTime: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: undefined
    },
    model: {
      type: String,
      default: undefined
    },
    priceinfo: {
      type: Object,
      default: () => ({})
    },
    categories: {
      type: Array,
      default: () => []
    },
    characteristics: {
      type: Array,
      default: () => []
    },
    colors: {
      type: Array,
      default: () => []
    },
    familyId: {
      type: Number,
      default: null
    },
    gtmOptions: {
      type: Object,
      default: () => ({})
    },
    showColors: {
      type: Boolean,
      default: true
    },
    resultsChanged: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      destroy: false,
      imgLoaded: false,
      replacementImage: '',
      isVisible: true,
      contentHeight: null,
      intersectionObserver: null,
      itemRef: null
    }
  },
  computed: {
    isClone() {
      return getPath(this.$el).some((element) => element.classList.contains('slick-cloned'))
    },
    productImage() {
      return this.replacementImage ? this.replacementImage : this.imgSrc
    },
    productImgLoaded() {
      return this.replacementImage ? true : this.imgLoaded
    },
    contentPlaceholderStyles() {
      return this.contentHeight ? { height: `${this.contentHeight}px` } : null
    },
    defaultConfiguration() {
      if (this.familyId === FAMILY_GIFTCARD) {
        return {
          assemblyType: PRODUCT_ASSEMBLYTYPES.unassembled.type
        }
      }

      return null
    }
  },
  methods: {
    colorMouseOver(imageUrl) {
      if (imageUrl) {
        this.replacementImage = imageUrl
      }
    },
    colorMouseLeave() {
      this.replacementImage = ''
    },
    preloadImage() {
      if (this.imgSrc !== null) {
        const img = document.createElement('img')
        img.onload = () => {
          this.imgLoaded = true
        }
        img.src = this.imgSrc
      }
    },
    delegateClick(e) {
      if (e.isTrusted && !e.target.href && this.$refs.link) {
        e.preventDefault()
        if (this.$store.getters['gtm/isAvailable']) {
          this.$store.dispatch('gtm/productClick', {
            list: this.gtmOptions.list,
            product: {
              id: this.productId,
              name: this.name,
              model: this.model,
              priceInfo: this.priceinfo,
              categories: this.categories,
              characteristics: this.characteristics
            },
            callback: () => {
              var clickEvent = new e.constructor(e.type, e)
              this.$refs.link.dispatchEvent(clickEvent)
            }
          })
        } else {
          var clickEvent = new e.constructor(e.type, e)
          this.$refs.link.dispatchEvent(clickEvent)
        }
      }
    },
    onIntersection(entries) {
      // Check if the component is intersecting with the viewport
      const isIntersecting = entries.some(entry => entry.isIntersecting)

      if (this.isVisible && !isIntersecting) {
        this.ensureContentHeight();
      }

      this.isVisible = isIntersecting

      if (isIntersecting) {
        this.ensureContentHeight();
      }
    },
    ensureContentHeight() {
      if (this.isVisible) {
        const cardElement = document.getElementById(`ProductTeaser_${this._uid}`)
        if (cardElement) {
          this.contentHeight = cardElement.getBoundingClientRect().height
        }
      }
    }
  },
  created() {
    this.preloadImage()
  },
  mounted() {
    this.itemRef = this.$refs[`ProductTeaserContainer_${this.productId}`]
    this.intersectionObserver = new IntersectionObserver(debounce(this.onIntersection, 10), {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    })

    this.intersectionObserver.observe(this.itemRef)

    this.$nextTick(() => {
      if (!this.isClone && !this.destroy) {
        this.$root.$emit('gtm:registration', {
          id: this.productId,
          name: this.name,
          model: this.model,
          priceInfo: this.priceinfo,
          categories: this.categories,
          characteristics: this.characteristics
        })
      }
    })
  },
  watch: {
    '$root.mobilefirstBreakpoint.key'() {
      this.contentHeight = null
    }
  },
  beforeDestroy() {
    this.intersectionObserver.disconnect()
    this.destroy = true
  }
}
</script>

<style lang="scss">
$productteaser-line-height: 1.2 !default;
$productteaser-transiton: $transition-base !default;

$productteaser-img-fallback-size: 80% !default;
$productteaser-img-fallback-color: $imgfallback !default;

$productteaser-price-font-size: $font-size-lg !default;

$productteaser-hover-boxshadow: map-get($shadows, 'productteaser') !default;

.product-teaser {
  height: 100%;
  line-height: $productteaser-line-height;
  transition: $productteaser-transiton;
  position: relative;

  .product-teaser-contentplaceholder {
    height: 100%;
  }

  .card {
    height: 100%;

    .card-img,
    .card-img-top,
    .card-img-bottom,
    .card-img-left,
    .card-img-right {
      margin: 0;

      img {
        display: block;
        width: 100%;
        max-width: none;
        object-fit: scale-down;
        height: 100%;
      }

      .is-fallback {
        .bi {
          display: block;
          position: absolute;
          inset: 0;
          margin: auto;
          width: $productteaser-img-fallback-size;
          height: $productteaser-img-fallback-size;
          color: $productteaser-img-fallback-color;
        }
      }
    }

    .card-header,
    .card-footer {
      background-color: transparent;
      border: 0 none;
    }

    .card-header {
      padding-bottom: 0;
    }

    .card-body {
      position: relative;
      @include clearfix();
    }

    .card-footer {
      padding-top: 0;

      // Fix because of the stretched link
      z-index: 2;
    }

    .product-deliverystate {
      float: right;
      margin: 0;

      .bi {
        margin: 0;

        // Fix because of the stretched link
        z-index: 2;
      }
    }

    .eyecatcher-stack {
      position: absolute;
      top: 0;
      right: 0;
      margin: $card-spacer-y $card-spacer-x 0 0;
      z-index: 2;
    }

    .product-description {}

    .product-price {

      .price-active,
      .price-original {
        font-size: $productteaser-price-font-size;
      }
    }

    .product-colors {}

    .product-wishlistbutton {}
  }

  &:hover {
    box-shadow: $productteaser-hover-boxshadow;

    .carousel & {
      box-shadow: set-nth($productteaser-hover-boxshadow, 3, $grid-gutter-width * 0.5);
    }
  }

  &.has-link {
    cursor: pointer;
  }
}
</style>
