var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$slots.default && _vm.$slots.default.length)?_c('vue-slick-carousel',_vm._g(_vm._b({ref:"carousel",class:[
    'carousel',
    {
      'carousel-loading': !_vm.initialized,
      'carousel-center': _vm.centerMode,
      'has-arrows': _vm.arrows,
      'has-dots': _vm.dots,
      'arrows-outside': _vm.arrows && _vm.arrowsOutside,
      'adaptive-height': _vm.options.adaptiveHeight
    }
  ],on:{"beforeChange":_vm.beforeChange,"init":_vm.carouselInitialized},scopedSlots:_vm._u([{key:"prevArrow",fn:function(data){return [_vm._t("prev",function(){return [_c('a',{staticClass:"btn btn-link slick-arrow slick-arrow-prev"},[_c('lazy-icon',{attrs:{"icon":"caret-left"}})],1)]},{"slotScope":data})]}},{key:"nextArrow",fn:function(data){return [_vm._t("next",function(){return [_c('a',{staticClass:"btn btn-link slick-arrow slick-arrow-next"},[_c('lazy-icon',{attrs:{"icon":"caret-right"}})],1)]},{"slotScope":data})]}},{key:"customPaging",fn:function(page){return [_c('button',[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(page))])])]}}],null,true)},'vue-slick-carousel',_vm.options,false),_vm.$listeners),[_vm._t("default")],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }