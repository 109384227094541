export function getPath (element = {}) {
  const path = []

  if (element.style === undefined) return path

  path.push(element)

  while (element.parentNode && element.parentNode.style !== undefined) {
    element = element.parentNode
    path.push(element)
  }

  return path
}

export function getLinkInPath (path = []) {
  return path.find(element => element.href)
}

export default {
  getPath,
  getLinkInPath
}
