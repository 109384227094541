<template>
  <div class="description-images">
    <c-carousel
      ref="ImagesCarousel"
      class="images-carousel"
      :arrows="$root.isDesktop"
      asNavFor="ImagesNavigation"
      :autoplay="false"
      :dots="!$root.isDesktop"
      focusOnSelect
      :infinite="false"
      @carousel:init="imagesCarouselChange"
      @afterChange="imagesCarouselChange"
    >
      <slot>
        <aspect-ratio ratio="4x3" class="is-fallback-image">
          <lazy-icon icon="images" />
        </aspect-ratio>
      </slot>
    </c-carousel>

    <c-carousel
      v-if="$root.isDesktop && showPreview && $slots.default"
      ref="ImagesNavigation"
      class="images-navigation"
      asNavFor="ImagesCarousel"
      :autoplay="false"
      :dots="false"
      focusOnSelect
      :infinite="false"
      :swipe="false"
      :slidesToShow="6"
      @carousel:init="imagesNavigationInit"
    >
      <slot>
        <aspect-ratio ratio="4x3" class="is-fallback-image">
          <lazy-icon icon="images" />
        </aspect-ratio>
      </slot>
    </c-carousel>
  </div>
</template>

<script>

import AspectRatio from '@/components/private/AspectRatio'

export default {
  name: 'CarouselWithpreview',
  components: {
    AspectRatio
  },
  props: {
    showPreview: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    imagesCarouselChange(currentIndex) {
      if (this.showPreview) {
        this.$refs.ImagesNavigation.$el.querySelectorAll('.slick-slide').forEach((slide, sIndex) => {
          slide.classList[sIndex === currentIndex ? 'add' : 'remove']('carousel-current')
        })
      }
    },
    imagesNavigationInit() {
      if (this.showPreview) {
        this.$nextTick(() => {
          this.$refs.ImagesNavigation.$el.querySelectorAll('.slick-slide').forEach((slide, sIndex) => {
            slide.addEventListener('click', (e) => {
              this.$refs.ImagesCarousel.$refs.carousel.goTo(sIndex)
            })
          })
        })
      }
    }
  }
}
</script>

<style lang="scss">
$images-carousel-gap: 0 !default;
$images-navigation-gap: $spacer !default;
$images-navigation-slide-gap: $spacer * 0.75 !default;
$images-navigation-slide-img-gap: $spacer * 0.5 !default;
$images-navigation-slide-img-border: 2px solid transparent !default;
$images-navigation-slide-current-img-border-color: $primary !default;

$images-img-fallback-size: 80% !default;
$images-img-fallback-color: $imgfallback !default;

//desktop
$breakpoint: $desktop-breakpoint !default;
$images-desktop-carousel-gap: $spacer !default;
$images-desktop-navigation-gap: $images-navigation-gap !default;
$images-desktop-navigation-slide-gap: $images-navigation-slide-gap !default;
$images-desktop-navigation-slide-img-gap: $images-navigation-slide-img-gap !default;
$images-desktop-navigation-slide-img-border: $images-navigation-slide-img-border !default;
$images-desktop-navigation-slide-current-img-border-color: $images-navigation-slide-current-img-border-color !default;

.description-images {
  position: relative;
  z-index: 1;

  .images-carousel {
    @include fullwidth-mobile();
    margin-bottom: $images-carousel-gap;
  }

  .images-navigation {
    margin-bottom: $images-navigation-gap;
    overflow: hidden;

    .slick-list {
      margin-left: $images-navigation-slide-gap * -0.5;
      margin-right: $images-navigation-slide-gap * -0.5;

      .slick-slide {
        padding-left: $images-navigation-slide-gap * 0.5;
        padding-right: $images-navigation-slide-gap * 0.5;

        img {
          padding-bottom: $images-navigation-slide-img-gap;
          border-bottom: $images-navigation-slide-img-border;
        }

        &.carousel-current {
          img {
            border-bottom-color: $images-navigation-slide-current-img-border-color;
          }
        }
      }
    }
  }

  .is-fallback-image {
    .bi {
      display: block;
      position: absolute;
      inset: 0;
      margin: auto;
      width: $images-img-fallback-size;
      height: $images-img-fallback-size;
      color: $images-img-fallback-color;
    }
  }

  &:hover {
    .images-carousel {
      .slick-arrow {
        opacity: 1;
      }
    }
  }

  @include media-breakpoint-up($breakpoint) {
    .images-carousel {
      margin-bottom: $images-desktop-carousel-gap;
    }

    .images-navigation {
      margin-bottom: $images-desktop-navigation-gap;

      .slick-list {
        margin-left: $images-desktop-navigation-slide-gap * -0.5;
        margin-right: $images-desktop-navigation-slide-gap * -0.5;

        .slick-slide {
          padding-left: $images-desktop-navigation-slide-gap * 0.5;
          padding-right: $images-desktop-navigation-slide-gap * 0.5;

          img {
            padding-bottom: $images-desktop-navigation-slide-img-gap;
            border-bottom: $images-desktop-navigation-slide-img-border;
          }

          &.carousel-current {
            img {
              border-bottom-color: $images-desktop-navigation-slide-current-img-border-color;
            }
          }
        }
      }
    }
  }
}
</style>
