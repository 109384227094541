<template>
  <div class="grid-carousel">
    <c-carousel v-bind="carouselOptions">
      <slot />
    </c-carousel>
  </div>
</template>

<script>
import { DESKTOPFIRST_BREAKPOINTS } from '@/components/public/content/Carousel'

export default {
  name: 'GridCarousel',
  props: {
    slidesOnMobile: {
      type: [Number, String],
      default: 1
    },
    slidesOnTablet: {
      type: [Number, String],
      default: 3
    },
    slidesOnDesktop: {
      type: [Number, String],
      default: 4
    },
    arrows: {
      type: Boolean,
      default: false
    },
    gtmOptions: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      initialized: false,
      carouselOptions: {
        autoplay: false,
        adaptiveHeight: false,
        arrows: this.arrows,
        arrowsOutside: this.$root.isDesktop,
        slidesToShow: Number(this.slidesOnDesktop),
        slidesToScroll: Number(this.slidesOnDesktop),
        responsive: [
          {
            breakpoint: DESKTOPFIRST_BREAKPOINTS[this.$root.dt.key],
            settings: {
              slidesToShow: Number(this.slidesOnTablet),
              slidesToScroll: Number(this.slidesOnTablet)
            }
          },
          {
            breakpoint: DESKTOPFIRST_BREAKPOINTS[this.$root.dm.key],
            settings: {
              slidesToShow: Number(this.slidesOnMobile),
              slidesToScroll: Number(this.slidesOnMobile)
            }
          }
        ]
      },
      gtmProducts: []
    }
  },
  methods: {
    registerGtmProduct(product) {
      this.gtmProducts.push(product)
    },
    gtm() {
      if (!this.initialized) {
        this.initialized = true
        this.$nextTick(() => {
          this.$store.dispatch('gtm/productImpressions', {
            list: this.gtmOptions.list,
            products: this.gtmProducts
          })
        })
      }
    }
  },
  created() {
    this.$root.$on('gtm:registration', this.registerGtmProduct)
  },
  watch: {
    gtmProducts() {
      this.gtm()
    }
  }
}
</script>

<style lang="scss">
// mobile

$gridcarousel-gutter: $grid-gutter-width !default;

// tablet
$gridcarousel-tablet: $tablet-breakpoint !default;

$gridcarousel-tablet-gutter: $grid-gutter-width !default;

.grid-carousel {
  @include fullwidth-mobile();

  .carousel {
    .slick-list {
      margin: ($gridcarousel-gutter * -1) ($gridcarousel-gutter * -0.5);
      padding-left: $grid-gutter-width * 0.5;

      .slick-track {
        .slick-slide {
          padding: $gridcarousel-gutter ($gridcarousel-gutter * 0.5);
          padding-right: $gridcarousel-gutter * 3.5;

          &.slick-current {
            ~ .slick-slide {
              margin-left: $gridcarousel-gutter * -3;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up($gridcarousel-tablet) {
    .carousel {
      .slick-list {
        margin: ($gridcarousel-tablet-gutter * -1) ($gridcarousel-tablet-gutter * -0.5);
        padding-left: 0;

        .slick-track {
          .slick-slide {
            padding: $gridcarousel-tablet-gutter ($gridcarousel-tablet-gutter * 0.5);

            &.slick-current {
              ~ .slick-slide {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
