<template>
  <div class="testimonial-carousel" v-if="showCarousel">
    <c-carousel v-bind="carouselOptions">
      <slot/>
    </c-carousel>
  </div>
</template>

<script>
import { DESKTOPFIRST_BREAKPOINTS, BASE_CAROUSEL_OPTIONS } from '@/components/public/content/Carousel'

const TESTIMONIAL_CAROUSEL_OPTIONS = Object.assign({}, BASE_CAROUSEL_OPTIONS, {
  adaptiveHeight: true,
  arrows: true,
  arrowsOutside: true,
  dots: false,
  slidesToShow: 2,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: DESKTOPFIRST_BREAKPOINTS.md,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
})

export default {
  name: 'TestimonialCarousel',
  data () {
    return {
      showCarousel: false,
      carouselOptions: TESTIMONIAL_CAROUSEL_OPTIONS
    }
  },
  mounted: function () {
    // hack: show carousel after mounted to prevent "reading length of undifinded" error
    setTimeout(() => {
      this.showCarousel = true
    }, 0)
  }
}
</script>

<style lang="scss">
// mobile

// desktop
$testimonialcarousel-desktop: $desktop-breakpoint !default;

$testimonialcarousel-slide-border: map-get($borders, 'base') !default;

.testimonial-carousel {
  @include component('y');

  @include media-breakpoint-up($testimonialcarousel-desktop) {
    .carousel {
      .slick-list {
        .slick-track {
          .slick-slide {
            border-left: $testimonialcarousel-slide-border;
          }
        }
      }
    }
  }
}
</style>
