<template>
  <vue-slick-carousel ref="carousel" :class="[
      'carousel',
      {
        'carousel-loading': !initialized,
        'carousel-center': centerMode,
        'has-arrows': arrows,
        'has-dots': dots,
        'arrows-outside': arrows && arrowsOutside,
        'adaptive-height': options.adaptiveHeight
      }
    ]" v-if="$slots.default && $slots.default.length" v-bind="options" v-on="$listeners" @beforeChange="beforeChange" @init="carouselInitialized">
    <slot />
    <template #prevArrow="data">
      <slot name="prev" :slotScope="data">
        <a class="btn btn-link slick-arrow slick-arrow-prev">
          <lazy-icon icon="caret-left" />
        </a>
      </slot>
    </template>

    <template #nextArrow="data">
      <slot name="next" :slotScope="data">
        <a class="btn btn-link slick-arrow slick-arrow-next">
          <lazy-icon icon="caret-right" />
        </a>
      </slot>
    </template>

    <template #customPaging="page">
      <button>
        <span class="sr-only">{{ page }}</span>
      </button>
    </template>
  </vue-slick-carousel>
</template>

<script>
import { BREAKPOINTS } from '@/assets/js/modules/scss-variables'

// docs: https://github.com/gs-shop/vue-slick-carousel/blob/master/docs/API.md
import VueSlickCarousel from 'vue-slick-carousel/dist/vue-slick-carousel.umd.min.js'
import { defaultValues, props } from 'vue-slick-carousel/src/defaultProps'

export const DESKTOPFIRST_BREAKPOINTS = BREAKPOINTS.desktopfirst.breakpoints

export const BASE_CAROUSEL_OPTIONS = Object.assign({}, defaultValues, {
  accessibility: true,
  adaptiveHeight: true,
  arrows: false,
  asNavFor: null,
  autoplay: true,
  autoplaySpeed: 6000,
  centerMode: false,
  centerPadding: '0',
  cssEase: 'ease',
  dots: true,
  dotsClass: 'slick-dots',
  draggable: true,
  edgeFriction: 0.35,
  fade: false,
  focusOnSelect: false,
  infinite: true,
  initialSlide: 0,
  lazyLoad: null,
  pauseOnDotsHover: true,
  pauseOnFocus: false,
  pauseOnHover: true,
  rows: 1,
  rtl: false,
  slidesPerRow: 1,
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 300,
  swipe: 'ontouchstart' in window || window.navigator.maxTouchPoints > 0 || window.navigator.msMaxTouchPoints > 0,
  swipeToSlide: false,
  touchMove: true,
  touchThreshold: 5,
  useCSS: true,
  useTransform: true,
  variableWidth: false,
  vertical: false,
  waitForAnimate: true,
  /* use breakpoints const to define responsive options
   *  e.g.
   *  responsive: [
   *   {
   *     breakpoint: DESKTOPFIRST_BREAKPOINTS.md,
   *     settings: { ... }
   *   }
   * ]
   */
  responsive: []
})

export default {
  name: 'Carousel',
  components: {
    VueSlickCarousel
  },
  props: Object.keys(props).concat('arrowsOutside'),
  data() {
    return {
      breakpoints: DESKTOPFIRST_BREAKPOINTS,
      options: this.getOptions(),
      currentSlide: null,
      initialized: false
    }
  },
  computed: {
    activeOptions() {
      let options = this.getOptions()
      const responsiveOptions = options.responsive

      responsiveOptions.forEach((o) => {
        if (o.breakpoint >= this.$root.viewport.width) options = Object.assign({}, options, o.settings)
      })

      return options
    }
  },
  methods: {
    carouselInitialized() {
      this.initialized = true
    },
    updateOptions() {
      const newOptions = this.getOptions()
      if (!this.optionsEqual(this.options, newOptions)) {
        this.options = newOptions
        if (this.currentSlide !== null) {
          this.$nextTick(() => {
            this.$refs.carousel.goTo(this.currentSlide)
          })
        } else {
          this.currentSlide = this.options.initialSlide
        }
        this.multislideAdaptiveHeight()
      }

      this.$emit('carousel:init', this.currentSlide)
    },
    optionsEqual(o1, o2) {
      const refKeys = Object.keys(BASE_CAROUSEL_OPTIONS)

      for (let i = 0; i < refKeys.length; i++) {
        const key = refKeys[i]
        if (o1[key] !== o2[key]) return false
      }

      return true
    },
    getOptions() {
      if (this.cachedOptions) {
        return this.cachedOptions
      }

      const inputOptions = this.$props
      const baseOptions = Object.assign(this.options || {}, BASE_CAROUSEL_OPTIONS)

      const inputResponsiveOptions = this.$props.responsive || []
      const baseResponsiveOptions = baseOptions.responsive.map((o) => o.breakpoint)
      const unicResponsiveOptions = inputResponsiveOptions.filter((o) => !baseResponsiveOptions.includes(o.breakpoint))

      // Overwrite baseOptions with inputOptions but not responsive options
      Object.keys(inputOptions).forEach((oKey) => {
        if (oKey === 'asNavFor') baseOptions[oKey] = this.getAsNavFor()
        else if (inputOptions[oKey] !== undefined && oKey !== 'responsive') baseOptions[oKey] = inputOptions[oKey]
      })

      // Overwrite baseResponsiveOptions with inputResponsiveOptions and concat with unicResponsiveOptions
      baseOptions.responsive = baseOptions.responsive
        .map((bOptions) => ({
          breakpoint: bOptions.breakpoint,
          settings: Object.assign(
            {},
            bOptions.settings,
            (inputResponsiveOptions.find((o) => o.breakpoint === bOptions.breakpoint) || {}).settings || {}
          )
        }))
        .concat(unicResponsiveOptions)

      this.cachedOptions = baseOptions
      return baseOptions
    },
    invalidateOptionsCache() {
      this.cachedOptions = null
    },
    beforeChange() {
      this.multislideAdaptiveHeight()
      this.updateCurrent()
    },
    multislideAdaptiveHeight() {
      if (!this.$refs.carousel) {
        return
      }
      const activeSlides = this.activeSlides || (this.activeSlides = Array.from(this.$refs.carousel.$el.querySelectorAll('.slick-active > div')))
      const isMultislideCarousel = activeSlides.length > 1
      const isAdaptiveHeightCarousel = this.activeOptions.adaptiveHeight
      let height = 0

      if (isMultislideCarousel && isAdaptiveHeightCarousel) {
        requestAnimationFrame(() => {
          activeSlides.forEach((el) => {
            const images = el.querySelectorAll('img')
            if (images.length) {
              images.forEach((img) => {
                if (img.complete) {
                  imageLoaded.call(this, el)
                } else {
                  img.addEventListener('load', () => imageLoaded.call(this, el))
                }
              })
            } else {
              imageLoaded.call(this, el)
            }
          })
        })
      }

      function imageLoaded(el) {
        height = Math.max(height, el.offsetHeight)
        if (!this.$refs.carousel) {
          return
        }
        this.$refs.carousel.$el.querySelector('.slick-list').style.maxHeight = `${height}px`
      }
    },
    updateCurrent(currentIndex, nextIndex) {
      this.currentSlide = nextIndex
    },
    // workaround for not working property "asNavFor"
    getAsNavFor() {
      const isString = typeof this.$props.asNavFor === 'string'
      const refElement = ((this.$parent.$refs[this.$props.asNavFor] || {}).$refs || {}).carousel || null

      return isString ? refElement : this.$props.asNavFor
    },
    setAsNavFor() {
      const newAsNavFor = this.getAsNavFor()
      if (this.options.asNavFor !== newAsNavFor) {
        this.options.asNavFor = newAsNavFor
      }
    }
  },
  mounted() {
    this.updateOptions()
  },
  watch: {
    $props: {
      deep: true,
      handler() {
        this.updateOptions()
      }
    },
    activeOptions() {
      this.multislideAdaptiveHeight()
    }
  }
}
</script>

<style lang="scss">
@import '~vue-slick-carousel/dist/vue-slick-carousel.css';

// mobile
$carousel-arrow-gap: $spacer !default;
$carousel-arrow-size: $spacer * 2 !default;
$carousel-arrow-bg: rgba($white, 0.33) !default;
$carousel-arrow-border: $border-width solid $gray-300 !default;
$carousel-arrow-icon-size: $font-size-sm !default;
$carousel-arrow-icon-color: $black !default;
$carousel-arrow-transition-in: opacity ease-out 300ms !default;
$carousel-arrow-transition-out: opacity ease-in 300ms !default;

$carousel-arrow-hover-bg: $gray-300 !default;
$carousel-arrow-hover-border: $carousel-arrow-border !default;

$carousel-arrow-outside-gap: $spacer !default;
$carousel-arrow-outside-size: $spacer * 2 !default;
$carousel-arrow-outside-bg: rgba($white, 0.33) !default;
$carousel-arrow-outside-border: $border-width solid $gray-300 !default;
$carousel-arrow-outside-icon-size: $font-size-sm !default;
$carousel-arrow-outside-icon-color: $black !default;

$carousel-dots-padding-y: $spacer * 1 !default;
$carousel-dots-padding-x: 0 !default;
$carousel-dots-max-width: 60% !default;
$carousel-dots-dot-size: $spacer * 0.5 !default;
$carousel-dots-dot-bg: rgba($dark, 0.8) !default;
$carousel-dots-dot-gutter: $spacer * 0.625 !default;

$carousel-dots-dot-active-bg: $dark !default;
$carousel-dots-dot-active-border: 0.13rem solid $carousel-dots-dot-active-bg !default;
$carousel-dots-dot-active-border-offset: nth($carousel-dots-dot-active-border, 1) * -1.9 !default;

// desktop
$carousel-breakpoint: $desktop-breakpoint !default;

$carousel-desktop-arrow-gap: $carousel-arrow-gap !default;
$carousel-desktop-arrow-size: $spacer * 3 !default;
$carousel-desktop-arrow-bg: $carousel-arrow-bg !default;
$carousel-desktop-arrow-border: $carousel-arrow-border !default;
$carousel-desktop-arrow-icon-size: inherit !default;
$carousel-desktop-arrow-icon-color: $black !default;

$carousel-desktop-arrow-hover-bg: $carousel-arrow-hover-bg !default;
$carousel-desktop-arrow-hover-border: $carousel-desktop-arrow-border !default;

$carousel-desktop-arrow-outside-gap: $carousel-arrow-outside-gap !default;
$carousel-desktop-arrow-outside-size: $spacer * 3 !default;
$carousel-desktop-arrow-outside-bg: $carousel-arrow-outside-bg !default;
$carousel-desktop-arrow-outside-border: $carousel-arrow-outside-border !default;
$carousel-desktop-arrow-outside-icon-size: $carousel-desktop-arrow-outside-size * 0.5 !default;
$carousel-desktop-arrow-outside-icon-color: $carousel-arrow-outside-icon-color !default;

$carousel-desktop-dots-inset: $spacer * 2 !default;
$carousel-desktop-dots-padding-y: 0 !default;
$carousel-desktop-dots-padding-x: 0 !default;
$carousel-desktop-dots-dot-size: $spacer * 0.6875 !default;
$carousel-desktop-dots-dot-bg: rgba($white, 0.8) !default;
$carousel-desktop-dots-dot-active-bg: $white !default;
$carousel-desktop-dots-dot-gutter: $spacer * 0.7 !default;

.carousel {
  position: relative;

  .slick-arrow {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    z-index: 3;
    margin: 0;
    padding: 0;
    width: $carousel-arrow-size;
    height: $carousel-arrow-size;
    background-color: $carousel-arrow-bg;
    border: $carousel-arrow-border;
    opacity: 0;
    box-shadow: none;
    border-radius: 50%;
    transform: translate3d(0, -50%, 0);
    transition: $carousel-arrow-transition-out;

    .bi {
      display: block;
      font-size: $carousel-arrow-icon-size;
      color: $carousel-arrow-icon-color;
    }

    &.slick-arrow-prev {
      left: 0;
      margin-left: $carousel-arrow-gap;
    }

    &.slick-arrow-next {
      right: 0;
      margin-right: $carousel-arrow-gap;
    }

    &.slick-disabled {
      display: none;
    }

    .is-touch & {
      opacity: 1;
    }
  }

  .slick-dots {
    @include list-unstyled();
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    margin: ($carousel-dots-dot-gutter * -0.5) auto;
    padding: $carousel-dots-padding-y $carousel-dots-padding-x;
    max-width: $carousel-dots-max-width;

    li {
      button {
        display: block;
        margin: $carousel-dots-dot-gutter * 0.5;
        padding: 0;
        width: $carousel-dots-dot-size;
        height: $carousel-dots-dot-size;
        background-color: $carousel-dots-dot-bg;
        border: 0 none;
        border-radius: 50%;
      }

      &.slick-active {
        button {
          position: relative;
          background-color: $carousel-dots-dot-active-bg;

          &:before {
            display: block;
            content: '';
            position: absolute;
            inset: $carousel-dots-dot-active-border-offset;
            border: $carousel-dots-dot-active-border;
            border-radius: inherit;
          }
        }
      }
    }
  }

  .slick-list {
    z-index: 1;
    transition: max-height 150ms ease-out;

    .slick-track {
      display: flex;
      align-content: stretch;

      .slick-slide {
        position: relative;
        float: none;
        height: auto;
        overflow: hidden;

        > div {
          height: 100%;
        }

        [style*='inline-block'] {
          display: block !important;
        }

        img {
          display: block;
          width: 100%;
          max-width: none;
          height: auto;
        }
      }
    }
  }

  &:hover {
    .slick-arrow {
      opacity: 1;
      transition: $carousel-arrow-transition-in;
    }
  }

  &.arrows-outside {
    .slick-arrow {
      top: calc(100% + #{$carousel-arrow-outside-gap});
      margin: 0;
      width: $carousel-arrow-outside-size;
      height: $carousel-arrow-outside-size;
      opacity: 1;
      transform: none;

      &.slick-arrow-prev {
        left: auto;
        right: 50%;
      }

      &.slick-arrow-next {
        left: 50%;
        right: auto;
      }
    }

    .slick-dots {
      margin-bottom: calc(#{$carousel-arrow-outside-gap * 2} + #{$carousel-arrow-outside-size});
    }

    &:not(.has-dots) {
      .slick-list {
        margin-bottom: 0;
      }
    }
  }

  &.adaptive-height {
    .slick-list {
      .slick-track {
        align-content: flex-start;

        .slick-slide {
          > div {
            height: auto;
          }
        }
      }
    }
  }

  @include media-breakpoint-up($carousel-breakpoint) {
    .slick-arrow {
      width: $carousel-desktop-arrow-size;
      height: $carousel-desktop-arrow-size;
      background-color: $carousel-desktop-arrow-bg;
      border: $carousel-desktop-arrow-border;
      display: flex;

      .bi {
        font-size: $carousel-desktop-arrow-icon-size;
        color: $carousel-desktop-arrow-icon-color;
      }

      &.slick-arrow-prev {
        margin-left: $carousel-desktop-arrow-gap;
      }

      &.slick-arrow-next {
        margin-right: $carousel-desktop-arrow-gap;
      }

      &:hover {
        background-color: $carousel-desktop-arrow-hover-bg;
        border: $carousel-desktop-arrow-hover-border;
      }
    }

    .slick-dots {
      position: absolute;
      bottom: $carousel-desktop-dots-inset;
      left: 0;
      right: 0;
      margin: ($carousel-desktop-dots-dot-gutter * -0.5) auto;
      padding: $carousel-desktop-dots-padding-y $carousel-desktop-dots-padding-x;

      li {
        button {
          margin: $carousel-desktop-dots-dot-gutter * 0.5;
          width: $carousel-desktop-dots-dot-size;
          height: $carousel-desktop-dots-dot-size;
          background-color: $carousel-desktop-dots-dot-bg;
        }

        &.slick-active {
          button {
            background-color: $carousel-desktop-dots-dot-active-bg;

            &:before {
              border-color: $carousel-desktop-dots-dot-active-bg;
            }
          }
        }
      }
    }

    &.arrows-outside {

      .slick-arrow {
        top: 50%;
        width: $carousel-desktop-arrow-outside-size;
        height: $carousel-desktop-arrow-outside-size;
        transform: translate3d(0, -50%, 0);

        &.slick-arrow-prev {
          right: calc(100% + #{$carousel-desktop-arrow-outside-gap});
        }

        &.slick-arrow-next {
          left: calc(100% + #{$carousel-desktop-arrow-outside-gap});
        }
      }

      .slick-dots {
        margin-bottom: 0;
      }
    }
  }
}
</style>
